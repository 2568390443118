<template>
  <div id="page-user-list">

    <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
      <div class="vx-row mb-6">
        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
              <ActivityForm></ActivityForm>
          </div>
          <div class="vx-card p-6 mb-6">
              <SeoInfo></SeoInfo>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
        </div>
      </div>

      <div class="vx-card p-4 mb-4">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
          </div>
      </div>
    </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import ActivityForm from './common/ActivityForm';
import AdditionInfo from './common/AdditionInfo';
import SeoInfo from './common/SeoInfo';
import loaderMixin from '../../../mixins/loaderMixin';
import {PACKAGE_ACTIVITY} from '../../../constant/entity-identifier'
import { mapGetters, mapActions } from 'vuex'

import {storeImage} from '../../../services/image';
import {
    CLEAR_FORM
} from '../mutation-types';

export default {
  components: {
    vSelect,
    ActivityForm,
    AdditionInfo,
    SeoInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'package_activity/getFormObj',
        images: 'package_activity/getImages'
    })
  },
  created() {
    this.clearForm();
      this.clearValidationErrors();
  },
  methods: {
    ...mapActions({
      createPackageActivity: 'package_activity/createPackageActivity',
      clearForm: 'package_activity/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(id, alt) {
      let formData = new FormData();
      formData.append("entity_id", id);
      formData.append("entity", PACKAGE_ACTIVITY);
      for (let i = 0; i < this.images.length; i++) {
          formData.append("images[" + i + "]", this.images[i]);
          formData.append("alt[" + i + "]", this.form.alt_image);
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.createPackageActivity(this.form)
        .then(async response => {
          if(this.form.images.length > 0) {
            await storeImage(this.prepareForm(response.data.data.id, this.form.alt_image));
          }
          this.closeLoading();
          this.$store.commit('package_activity/' + CLEAR_FORM);
          this.$vs.notify({
              title: 'Package Activity Created',
              text: 'Package activity created successfully.',
              color: 'success',
              position: 'top-right'
          });
          this.$router.push('/package-activities');
        }).catch(err => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
